<template lang="pug">
modal(size="base" @close='$emit("close")')  
  generic-form(
    :endpoint='`/_/RequestChangesForm`'
    :allowShortcuts='true'
    @submitSuccess='$emit("close")'
  )
    template(#default)
      form-header(class='mb-2')
        | {{$t("Change Request")}}
        template(#below)
          p(class='m-0') {{ $t("inquiry.change_request_message") }}
      form-hidden(name='Id' :modelValue='id')
      form-textarea(name="DeclineReason" markOptional)
      form-footer
        form-action(type="secondary" :primary='true' name="SendChangeRequest")
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormRequestChanges = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
})
export default FormRequestChanges
</script>
